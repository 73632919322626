import React from 'react'
import { graphql } from 'gatsby'
import { useUrl } from '../fragments/hooks/useUrl'
import Seo from '../components/seo'
import NotFound from '../components/NotFound/NotFound'
const PageThankYou = ({
    location,
    pageContext,
    data: {
        contentfulPageCustom,
        allContentfulPageCustom: {
            nodes
        }
    }
  }) => {
    const{ description, slug, seoTitle, seoDescription, cta, title} = contentfulPageCustom
    const lang = useUrl()
  return (
    <section id = 'PageThankYou'>
      <Seo
        slug={slug}
        title={seoTitle}
        description={seoDescription && seoDescription.seoDescription}
        node_locale={pageContext.node_locale}
        lang={lang}/>
        <NotFound data = {contentfulPageCustom}/>
    </section>
  )
}

export const PageThankYouQuery = graphql`
    query pageThankYou($id:String!, $contentful_id: String! ){
        contentfulPageCustom(id:{eq:$id}){
            seoTitle
            seoDescription{
              seoDescription
            }
            node_locale
            title
            description{
              raw
            }
            slug
            cta{
                title
                slug
            }
        }
        allContentfulPageCustom(filter: {contentful_id: {eq: $contentful_id}, id: {ne: $id},  slug: { ne: null }}){
            nodes {
                slug
                node_locale
            }
        }
    }
`
export default PageThankYou